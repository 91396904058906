<template>
  <div class="users-view">
    <tab-component>
      <template>
        <h1>Brugere</h1>
      </template>
      <template #taboverride>
        <button class="button is-dark" @click="registerUser">Tilføj ny bruger</button>
      </template>
    </tab-component>
    <section>
      <b-table :data="appUsers" 
              :loading="loading"
              @click="onRowClick"
              hoverable
              striped>

        <b-table-column field="name" label="Name" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="email" label="E-mail" v-slot="props">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column field="role" label="Role" v-slot="props">
          {{ props.row.role }}
        </b-table-column>

        <b-table-column cell-class="has-text-right">
          <span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
          </span>
        </b-table-column>

      </b-table>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ApiState from '../enums/APISTATE';

export default {
  name: 'users-view',
  data: () => ({

  }),
  async mounted() {
    this.updatePageTitle('Bruger administration');

    await this.loadAppUsers();
  },
  computed: {
    ...mapGetters('appUsers', [
      'appUserApiState',
      'appUsers'
    ]),
    loading() {
      return this.appUserApiState !== ApiState.LOADED;
    }
  },
  methods: {
    ...mapActions(['updatePageTitle']),
    ...mapActions('appUsers', ['loadAppUsers']),
    onRowClick(row) {
      this.$router.push(`/users/${row.id}/edit`);
    },
    registerUser() {
      this.$router.push('/users/new');
    }
  }
}
</script>

<style lang="scss">
  .users-view {
    .register-user {
      cursor: pointer;
      width: 100%;
    }
  }
</style>
